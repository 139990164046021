'use client';
import styles from './styles.module.scss';
import Link from '@/components/WrappedLink';
import colors from '@/theme/colors.module.scss';
import { HeaderCar } from '../../../../public/Images';
import Typography from '@/components/shared/typography/typography.component';
import { useTranslation } from '@/hook';
import Image from 'next/image';
import { possibleBuyUseCarsSources } from '@/types/filter.types';
import { ACTION_CLICK_BUY_CAR } from '@/constants/actions';
import { useAnalytics } from '@/integrations';

export default function Header({ lang }: { lang: 'ar' | 'en' }) {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();

    return (
        <section className={styles.container}>
            <div className={styles.infoContainer}>
                <Typography color={colors.secondary} variant="h4Bold">
                    {t('Buy verified car')}
                </Typography>

                <Typography
                    color={colors.primary700}
                    className={styles.subHeader}
                    variant="subtitle2Medium"
                >
                    {t('Car down payment')}
                </Typography>
                <div className={styles.buttonsContainer}>
                    <Link
                        className={styles.actionButton}
                        onClick={() => {
                            trackEvent(ACTION_CLICK_BUY_CAR, {
                                source: possibleBuyUseCarsSources.Browse_Cars_Homepage,
                            });
                        }}
                        href={`/${lang}/buy-used-cars`}
                    >
                        <Typography variant="body1Medium">
                            {t('Buy Car')}
                        </Typography>
                    </Link>
                    <Link
                        className={`${styles.actionButton} ${styles.inverted}`}
                        href={`/${lang}/sell-car`}
                    >
                        <Typography variant="body1Medium">
                            {t('Sell Car')}
                        </Typography>
                    </Link>
                </div>
            </div>
            <Image
                loading={'eager'}
                quality={80}
                width={771}
                height={432}
                src={HeaderCar}
                alt="Car Header"
                className={styles.image}
            />
        </section>
    );
}
